import React from "react";
// components
import Chart, { useChart } from "../chart";
import constants from "../../utilities/constants";
import { largestDivisorOrIncrement } from "../../utilities/largestDivisorOrIncrement";
import {
  aiFailurePerformanceRateStartStrategy,
  aiSuccessPerformanceRateStartStrategy,
  averageBotResponseTimeStrategy,
  averageMessagesPerSessionStrategy,
  averageSessionDurationStrategy,
  escalationRateStrategy,
  retentionRateStrategy,
} from "../../utilities/analyticsDataStrategies";
import { statLabel } from "../../utilities/statLabel";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

// Mapping of strategies
const strategies = {
  averageSessionDuration: averageSessionDurationStrategy,
  averageMessagesPerSession: averageMessagesPerSessionStrategy,
  retentionRate: retentionRateStrategy,
  aiSuccessPerformanceRate: aiSuccessPerformanceRateStartStrategy,
  aiFailurePerformanceRate: aiFailurePerformanceRateStartStrategy,
  escalationRate: escalationRateStrategy,
  averageBotResponseTime: averageBotResponseTimeStrategy,
};

// ----------------------------------------------------------------------

export default function ChartArea({
  month,
  averageSessionDuration,
  averageMessagesPerSession,
  retentionRate,
  aiSuccessPerformanceRate,
  aiFailurePerformanceRate,
  escalationRate,
  averageBotResponseTime,
}) {
  const { t } = useTranslation();
  // Determine which data is present and execute the corresponding strategy

  let dataKey = null;
  let relevantData = null;

  if (averageSessionDuration) {
    dataKey = "averageSessionDuration";
    relevantData = averageSessionDuration;
  } else if (averageMessagesPerSession) {
    dataKey = "averageMessagesPerSession";
    relevantData = averageMessagesPerSession;
  } else if (retentionRate) {
    dataKey = "retentionRate";
    relevantData = retentionRate;
  } else if (aiSuccessPerformanceRate) {
    dataKey = "aiSuccessPerformanceRate";
    relevantData = aiSuccessPerformanceRate;
  } else if (aiFailurePerformanceRate) {
    dataKey = "aiFailurePerformanceRate";
    relevantData = aiFailurePerformanceRate;
  } else if (escalationRate) {
    dataKey = "escalationRate";
    relevantData = escalationRate;
  } else if (averageBotResponseTime) {
    dataKey = "averageBotResponseTime";
    relevantData = averageBotResponseTime;
  }

  const data = dataKey ? strategies[dataKey](relevantData, month) : [];

  // Maximum value of the y-axis --------------------------------
  const maxYaxis =
    relevantData?.length > 0
      ? Math.ceil(
          Math.max(
            ...relevantData?.map((el) =>
              dataKey === "averageSessionDuration"
                ? el.averageDurationInSeconds / 60
                : dataKey === "averageMessagesPerSession"
                ? parseInt(el.averageMessagesPerSession)
                : dataKey === "retentionRate"
                ? el.retentionRate
                : dataKey === "aiSuccessPerformanceRate"
                ? el.successRate
                : dataKey === "aiFailurePerformanceRate"
                ? el.failureRate
                : dataKey === "escalationRate"
                ? el.escalationRate
                : el.averageResponseTime
            )
          ) / 10
        ) * 10
      : 120;

  // Number of ticks on the y-axis (0, 10, 20, 30, 40, 50) ----------------
  const tickAmountForYaxis =
    relevantData?.length > 0
      ? relevantData?.length >= 10
        ? largestDivisorOrIncrement(relevantData.length) / 2
        : largestDivisorOrIncrement(relevantData.length)
      : 6;

  // Label for y-axis
  const label =
    dataKey === "averageSessionDuration"
      ? t("mins")
      : ["averageBotResponseTime", "retentionRate"].includes(dataKey)
      ? t("sec")
      : [
          "aiSuccessPerformanceRate",
          "aiFailurePerformanceRate",
          "escalationRate",
        ].includes(dataKey)
      ? "%"
      : "";

  // Chart data
  const series = [
    {
      name: statLabel(dataKey, t),
      data,
    },
  ];

  // Chart options
  const chartOptions = useChart({
    xaxis: {
      type: "categories", // Change this to 'category'
      categories: !month ? constants.MONTHS_ABBREV : constants.DAYS,
      tickAmount: 12,
    },
    yaxis: {
      min: 0, // Minimum value of the y-axis
      max: maxYaxis,
      tickAmount: tickAmountForYaxis,
      labels: {
        formatter: function (value) {
          // Convert the value to a string and split it by the decimal point
          const valueString = value.toString();
          const decimalPart = valueString.split(".")[1];

          // Check if the decimal part exists and has more than two digits
          if (decimalPart && decimalPart.length > 2) {
            return value.toFixed(2) + label;
          } else {
            return value + label;
          }
        },
      },
    },
    tooltip: {
      x: {
        format: "MMM",
      },
    },
    colors: ["#2BA7DF"],
  });

  return (
    <Chart
      type="area"
      series={series}
      options={chartOptions}
      height={353}
      width={"100%"}
    />
  );
}
